
import React, { useEffect, useState } from 'react';
import './Home.css';
import { BasePage, Carousel, BlockContent, QuoteBar } from '../../common';
import useScript from '../../modules/hooks/useScript';
import API from '../../modules/api';

export default function Home() {
	const [data, setData] = useState({});
	useScript({
		className: 'tg-review-carousel-widget',
		type: 'text/javascript',
		src: 'https://api.carehome.co.uk/assets/js/review_widget_carousel.js?displaydiv=tgrcw_cc64f855&displayid=71961&displaycount=50&displaylogo=true&displayscore=true&displaybackground=false&displayratingreview=true&displaylink=true'
	})


	async function getData() {
		const response = await API.getContent(API.CONTENT_TYPES.HOME);
		setData(response)
	}

	useEffect(() => {
		getData()
	}, [])


	return (
		<BasePage>
			{!!data?.carousel && <Carousel _data={data?.carousel} />}
			<div className="content">
				{!!data?.intro && <BlockContent data={data?.intro} />}
				{!!data?.showReview && <div className="tg-review-carousel-widget-container max-width" id="tgrcw_cc64f855"/>}
			</div>
			{!!data?.quotes && <QuoteBar quotes={data?.quotes}/>}
		</BasePage>
	);
}