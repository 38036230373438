
import React, { useState, useEffect } from 'react';
import './CareHomePicker.css'
import API from '../../modules/api';
import { useHistory } from "react-router-dom";


export default function CareHomePicker({ className='' }) {
	const history = useHistory();
	const [data, setData] = useState([]);


	async function getData() {
		const response = await API.getContent(API.CONTENT_TYPES.CAREHOME, true);
		const homes = response.filter(x => x.showOnPicker === true);
		setData(homes)
	}

	useEffect(() => {
		getData()
	}, [])

	function onClick(home) {
		history.push('/care-homes/' + home.slug.current);
	}
	
	return (
		<div className={"chp" + className}>
			{data?.map((home, i) => {
				return (<div key={i} className="chp-item" onClick={() => onClick(home)}>
						<img src={API.urlForAsset(home.image)} alt={home.name}/>
						<div className="chp-item-title">{home.name + ' 	›'}</div>
					</div>)
			})}
		</div>
	);
}