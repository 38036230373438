import React, { useEffect, useState } from 'react';
import API from '../modules/api';
import ReactPlayer from 'react-player/lazy'

export default function VideoBlock({
  className = '',
  data,
}) {
  const [url, setURL] = useState(null);

  function getFileURL() {
    let url = API.fetchFileReference(data?.videoFile, 'file');
    console.log(url);
    setURL(url)
  }

  useEffect(() => {
    if (data?.youtubeURL) {
      setURL(data?.youtubeURL);
    } else if (data?.vimeoURL) {
      setURL(data?.vimeoURL);
    } else {
      getFileURL();
    }
  }, [data])


  return (
    <div className={"video-block " + className}>
      <div className='video-block-video'>
        {url && <ReactPlayer
          url={url}
          controls
          height={'400'}
          width={'100%'}
        />}
      </div>
    </div>
  );
}