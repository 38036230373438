
import React from 'react';
import { Link } from 'react-router-dom'
import './Footer.css';
import { useSelector } from 'react-redux';
import { AiFillInstagram, AiFillFacebook } from 'react-icons/ai';
import { BlockContent } from '../common';
import birds from '../assets/birds_pink.png';
import awards from '../assets/care-awards.jpg';
import awards2 from '../assets/care-awards-2.jpg';
import support from '../assets/support-logo.jpg';


export default function Footer({
	data
}) {

	function socialLinkClicked(x) {
		switch (x) {
			case 0:
				window.location.href = data?.instagram;
				break;
			case 1:
				window.location.href = data?.facebook;
			default:
				break;
		}
	}

	return (
		<div className='footer'>
			<div className='footer-content max-width'>
			<div className='footer-links'>
				<div style={{flex: 1}}/>
				<div className='footer-social'>
					<AiFillInstagram className={'footer-social-link'} size={30} onClick={() => socialLinkClicked(0)}/>
					<div style={{width: 6}}/>
					<AiFillFacebook className={'footer-social-link'} size={30} onClick={() => socialLinkClicked(1)}/>
				</div>
				<Link className='footer-link' to={'/pages/privacy-policy'}>Privacy Policy</Link>
				<Link className='footer-link' to={'/pages/accessibility'}>Accessibility Statement</Link>
				<Link className='footer-link' to={'/contact'}>Contact Us</Link>
			</div>
			<div className='footer-copyright'>
				<div style={{flex: 1}}/>
				<div className='footer-images'>
					<img className='footer-img-awards' src={awards} alt='care awards' />
					<img className='footer-img-awards' src={awards2} alt='care awards' />
					<img className='footer-img-awards' src={support} alt='care awards' />
					<img className='footer-birds' src={birds} alt='birds' />
				</div>
				<BlockContent data={data?.copyright} />
			</div>
			</div>
		</div>
	);
}

