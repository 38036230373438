
import React, { useState, useEffect} from 'react';
import API from '../modules/api';
import { useParams } from 'react-router-dom';
import { BasePage, Carousel, BlockContent, ContactBar } from '../common';


export default function GenericPage() {

	const params = useParams();
	const [data, setData] = useState({});


	async function getData() {
		let response = await API.getSlug(API.CONTENT_TYPES.GENERICPAGE, params.slug);
		if (!response) {
			response = await API.getSlug(API.CONTENT_TYPES.GENERICPAGE, 'care-homes');
		}
		setData(response)
	}

	useEffect(() => {
		getData()
	}, [])


	return (
		<BasePage>
			{!!data?.carousel && <Carousel _data={data?.carousel} />}
			<div className="content">
				{!!data?.body && <BlockContent data={data?.body} />}
			</div>
			{!!data?.showContact && <ContactBar />}
		</BasePage>
	);
}