
import React from 'react';
import './News.css';

export default function NewsArchivePicker({
  onItemClick,
  selectedIndex,
  years
}) {



	return (
		<div className='news-archive-picker'>
      <h4>News Archive</h4>
      {Object.keys(years)?.reverse().map((key, i) => {
        const year = years[key];
        return <div 
          className='news-archive-picker-item'
          style={{fontWeight: selectedIndex === key ? 'bold' : 'normal' }} 
          key={i} 
          onClick={() => onItemClick(key)}
        >{`${key} - ${year.length} Article${year.length != 1 ? "s" : ""}`}</div>
      })}
		</div>
	);
}