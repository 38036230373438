
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';


export default function BasePage({ children }) {
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname);
	}, [location.pathname])

	return (
		<div className="page">
      {children}
		</div>
	);
}