
import React, { useEffect, useMemo, useState } from 'react';
import './News.css';
import API from '../../modules/api'
import Functions from '../../modules/functions'
import moment from 'moment';


export default function NewsArticleSummary({
  article,
  onClick = () => { }
}) {
  const [imgURL, setImgURL] = useState('');

  async function getImage() {
    if (article?.images[0] != null) {
      let ref = article?.images[0];
      if (ref?.image == null) {
        ref = await API.fetchReference(article?.images[0], 'singleImage');
      }
      setImgURL(API.urlForAsset(ref?.image));
    }
  }

  useEffect(() => {
    getImage();
  }, [article])

  const legacyText = useMemo(() => {
    const blockText = Functions.blockToPlainText(article?.body);
    let text = blockText.length > 0 ? blockText : article?.legacyText;
    return text.replace(/[^a-zA-Z0-9,. ]/g, "").substring(0, 300) + '...';
  }, [article])

  function navigate() {
    onClick(article?.slug)
  }

  return (
    <div className='news-summary'>
      {!!(article?.images?.length > 0) && <img src={imgURL} key={imgURL} alt={article?.title} onClick={navigate} />}
      <div className='news-summary-copy'>
        {!!article?.publishedAt && <div className="news-summary-date">{moment(article?.publishedAt).format('Do MMMM YYYY')}</div>}
        {!!article?.title && <h4 onClick={navigate}>{article?.title}</h4>}
        <p>{legacyText}</p>
        <div className='news-summary-read-more' onClick={navigate}>Read More	›</div>
      </div>
    </div>
  );
}