
import React from 'react';
import './ContactBar.css'
import { useForm, ValidationError } from '@formspree/react';


export default function ContactBar({
	className = '',
	title = 'Get in Touch',
	subtitle = 'Send us a message and we will respond as soon as possible'
}) {
	const [state, handleSubmit] = useForm("xvolpjka");
	const submittedTitle = "Thank you for your message, we'll be in touch shortly!";


	return (
		<div className={'cb ' + className}>
			<h1 className='max-width'>{!state.succeeded ? title : submittedTitle}</h1>
			{!state.succeeded && <div className='cb-subtitle max-width'>{subtitle}</div>}
			{!state.succeeded &&
				<form onSubmit={handleSubmit} className='cb-form max-width'>
					<div className='cb-fields'>
						<div className='xcolumn xflex-half'>
							<div className='cb-row cb-top-row'>
								<input
									className='cb-field cb-top-field'
									id="name"
									type="text"
									name="name"
									required
									placeholder="Name (required)"
								/>
								<ValidationError
									prefix="Name"
									field="name"
									errors={state.errors}
								/>
								<div style={{ width: 24 }} />
								<input
									className='cb-field cb-phone cb-top-field'
									id="phone"
									type="text"
									name="phone"
									placeholder="Phone"
								/>
								<ValidationError
									prefix="Phone"
									field="phone"
									errors={state.errors}
								/>
							</div>
							<div style={{ height: 24 }} />
							<input
								className='cb-field cb-row cb-email'
								id="email"
								type="email"
								name="email"
								required
								placeholder="Email Address (required)"
							/>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</div>
						<div className='xcolumn xflex-half'>
						<textarea
							className='cb-message-field cb-row xflex-half'
							id="message"
							name="message"
							required
							placeholder="Message"
						/>
						<ValidationError
							prefix="Message"
							field="message"
							errors={state.errors}
						/>
						</div>
					</div>
					<div className='xrow xcenter'>
						<button
							type="submit"
							disabled={state.submitting}
							className='cb-submit'>
							Submit
						</button>
					</div>
				</form>
			}
			{state.errors?.length > 0 && <div style={{ color: 'red' }}>{state.errors[0].message}</div>}
		</div >
	);
}