import React from 'react';
import './ImageBlock.css'
import API from '../../modules/api';


export default function SingleImage({
  data,
  onClick=()=>{},
  single=false
}) {

  return (
    <div 
      className={"ib-item " + (single? "ib-item-single" : "ib-item-block")} 
      onClick={() => onClick(data)}>
        <img src={API.urlForAsset(data?.image)} alt={'img'}/>
        {!!data?.showTitle && <div className="ib-item-title">{data?.title}</div>}
    </div>
  );
}