import { createStore } from 'redux'


const initialState = {
	home: {carousel:[], services:[]},
	news: {},
	join: {carousel:[], dropdown:[]},
	newsArticles: [],
	contact: {},
	settings: {},
	careHomes: [],
	clients: {carousel:[]}
}


function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SETDATA':
			return action.data
		case 'SETARTICLES': 
			return {...state,
				newsArticles: action.articles
			}
    default:
      return state
  }
}


let store = createStore(reducer)


function setData(data) {
	store.dispatch({type:'SETDATA', data})
}

function setArticles(articles) {
	store.dispatch({type:'SETARTICLES', articles})
}

export default {
	store,
	setData,
	setArticles,
	initialState
}