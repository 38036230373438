
import React from 'react';
import './QuoteBar.css'
import BSCarousel from 'react-bootstrap/Carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import QuoteSlide from './QuoteSlide';

export default function QuoteBar({
  className,
  quotes
}) {



	return (
		<div className={['carousel', className]}>
			<BSCarousel
				interval={10000}
				className='quote-carousel'
				indicators={false}
				prevIcon={<FiChevronLeft className={"quote-chevron"} size={30} />}
				nextIcon={<FiChevronRight className={"quote-chevron"} size={30} />}>
				{
					quotes?.map((x, i) => {
						return (
							<BSCarousel.Item key={i}>
								<QuoteSlide quote={x} />
							</BSCarousel.Item>
						)
					})
				}
			</BSCarousel>
		</div>
	);
}