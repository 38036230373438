import React, { useEffect, useState } from 'react';
import './OurHomes.css';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { BlockContent } from '../../common';


export default function WhereAreWe({
  data,
  className = ''
}) {

  const location = data.location ? [data.location.lat, data.location.lng] : [0, 0];
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [])

  return (
    <div className={'waw ' + className}>
      <h1>Where Are We</h1>
      <div className='waw-content max-width-extra'>
        {!isIE && loaded &&
          <Map center={location} zoom={13} scrollWheelZoom={false} dragging={false}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={location}>
              <Popup>{data?.name}</Popup>
            </Marker>
          </Map>}
          <div className='waw-info'>
            {!!data?.locationDescription && <BlockContent data={data?.locationDescription} />}
          </div>
      </div>
    </div>
  );
}