
import React, { useState, useEffect } from 'react';
import './OurCare.css';
import API from '../../modules/api';
import { BasePage, Carousel, BlockContent, QuoteBar } from '../../common';

export default function OurCare() {
	const [index, setIndex] = useState(0);
	const [data, setData] = useState({});


	async function getData() {
		const response = await API.getContent(API.CONTENT_TYPES.OURCARE);
		setData(response)
	}

	useEffect(() => {
		getData()
	}, [])


	return (
		<BasePage>
			{!!data?.carousel && <Carousel _data={data?.carousel} />}
			<div className="content care">
				<div className="care-picker max-width">
					{data?.sections?.map((x, i) => {
						return <div className="care-picker-section" onClick={() => setIndex(i)}>
							<div className='care-picker-section-title'>{x.title}</div>
							<div className='care-picker-section-line' style={{backgroundColor: i===index ? '#f4bcca' : '#F5F5F5'}}/>	
						</div>
					})}
				</div>
				{!!data?.sections && <BlockContent data={data?.sections[index]?.body} />}
			</div>
			{!!data?.quotes && <QuoteBar quotes={data?.quotes}/>}
		</BasePage>
	);
}