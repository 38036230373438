
import React, { useState } from 'react';
import './BrochureForm.css'
import API from '../../modules/api';
import { FiDownload } from 'react-icons/fi';



export default function BrochureForm({
	className,
	data,
}) {
	const [form, setForm] = useState({ name: "", email: "", phone: "" })
	const [submitted, setSubmitted] = useState(false);

	async function onSubmit(event) {
		event.preventDefault();

		const response = await API.subscribeBrochure(form);
		if (response) {
			setSubmitted(true)
			const link = document.createElement('a');
			link.href = 'CoastCareHomes_Brochure.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}


	return (
		<div className={"brochure max-width-extra " + className}>
			<div className="brochure-content">
				<h1>{submitted ? "Thanks! Your brochure is downloading..." : data?.title}</h1>
				<div className='brochure-subtitle'>{submitted ? "" : data?.subtitle}</div>
				{submitted ||
					<form onSubmit={onSubmit} className='brochure-form'>
						<div className='brochure-form-fields'>
							<div className='brochure-form-column'>
								<input
									className='brochure-field'
									id="name"
									type="text"
									name="name"
									required
									placeholder="First Name*"
									onChange={({ nativeEvent }) => setForm({ ...form, name: nativeEvent.target.value })}
								/>
								<input
									className='brochure-field'
									id="email"
									type="email"
									name="email"
									required
									placeholder="Email Address*"
									onChange={({ nativeEvent }) => setForm({ ...form, email: nativeEvent.target.value })}
								/>
							</div>
							<div className={'brochure-form-column'}>
								<input
									className='brochure-field'
									id="phone"
									name="phone"
									placeholder="Phone Number"
									onChange={({ nativeEvent }) => setForm({ ...form, phone: nativeEvent.target.value })}
								/>
								<div className={'brochure-required-flag'}>* Required fields</div>
							</div>
						</div>
						<button
							type="submit"
							disabled={false}
							className='brochure-form-submit'>
							<FiDownload className={"brochure-form-submit-icon"} size={25} />
							{data?.button}
						</button>
					</form>
				}
				{submitted || <div className={'brochure-download-anyway'}>
					<a href='CoastCareHomes_Brochure.pdf' download>Download directly</a>
				</div>}
			</div>
		</div>
	);
}