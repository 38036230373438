
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CarouselSlide.css';
import API from '../../modules/api'
import ReactPlayer from 'react-player/lazy'
import { MdPlayArrow } from 'react-icons/md';


export default function CarouselSlide(props) {
	const history = useHistory();
	const [playing, setPlaying] = useState(false);

	function onClick() {
		const link = props?.item?.target;
		console.log('LINK: ', link);
		if (link) {
			if (link.lastIndexOf('http', 0) === 0) {
				window.location.href = link;
			} else {
				history.push(link); 
			}
		} else if (props?.item?.videoUrl) {
			setPlaying(true);
		}
	}

	return (
		<div 
			className='carousel-slide' 
			style={{ backgroundImage: playing || `url(${API.urlForAsset(props.item.image)})` }}
			onClick={onClick}>
			{!!props?.item?.title && <div className='carousel-slide-labels'>
				<h1 className='carousel-slide-title'>{props?.item?.title + (props?.item?.target ? " ›" : "")}</h1>
				<p className='carousel-slide-subtitle'>{props?.item?.subtitle}</p>
			</div>}
			{props?.item?.videoUrl && <div className='carousel-video-button'><MdPlayArrow size={60} className='carousel-video-button-icon'/></div>}
				{playing && <ReactPlayer
					style={{zIndex: 2, position: 'absolute'}}
          url={props.item?.videoUrl}
          controls
          height={'100%'}
          width={'100%'}
        />}
		</div>
	);
}