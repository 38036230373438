import React from 'react';


export default function Tour({
  data
}) {

  return (
    <div className={"tour-block max-width"}>
      {!!data?.title && <h2 className='tour-block-title'>{data?.title}</h2>}
      {!!data?.url && <iframe frameBorder={0} className='tour-block-iframe' src={data?.url ?? ""}/>}
    </div>
  );
}