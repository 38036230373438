

const validateEmail = (email) => {
	if (!email) { return false; } 
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
}

function blockToPlainText(blocks = []) {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n\n')
}


export default {
	validateEmail,
	blockToPlainText
}