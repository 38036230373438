import imageUrlBuilder from '@sanity/image-url';
import { getFile } from '@sanity/asset-utils'
import axios from 'axios'; 
import store from '../store';

const CONTENT_TYPES = {
	HOME: 'home',
	JOIN: 'join',
	NEWS: 'news',
	NEWSARTICLE: 'newsArticle',
	CAREHOME: 'carehome',
	OURCARE: 'ourCare',
	GENERICPAGE: 'page',
	SETTINGS: 'siteSettings'
}

const client = require('@sanity/client')({
  projectId: '0tehomvh',
  dataset: 'production',
  useCdn: true
});
const builder = imageUrlBuilder(client);

const sortArticles = (array) => {
	return array.sort((a,b) => {
		if (a.publishedAt > b.publishedAt) return -1;
		if (a.publishedAt < b.publishedAt) return 1;
		return 0;
	});
}

const getContent = async (type, list = false) => {
	return client.fetch(`*[_type == "${type}"]`).then(results => {
		if (list) {
			return results.filter(x => x._type === type)
		}
		return results.find(x => x._type === type);
	});
}

const getAllData = async () => {
	return client.fetch('*[_type != "newsArticle"]').then(results => {
		const data = store.initialState
		data.home = results.find(x => x._type === 'home');
		data.join = results.find(x => x._type === 'join');
		data.news = results.find(x => x._type === 'news');
		data.clients = results.find(x => x._type === 'clients');
		data.contact = results.find(x => x._type === 'contact');
		data.careHomes = results.filter(x => x._type === 'carehome');
		data.ourCare = results.find(x => x._type === 'ourCare');
		data.pages = results.filter(x => x._type === 'page');
		data.settings = results.find(x => x._type === 'siteSettings');
		store.setData(data);
		return data;
	});
}


const getArticles = async (year) => {
	return client.fetch('*[_type == "newsArticle"]').then(results => {
		const articles = sortArticles(results.filter(x => x._type === 'newsArticle'));
		return articles;
	});
}


const getSlug = async (type, slug) => {
	return client.fetch(`*[_type == "${type}" && slug.current == "${slug}"]`).then(results => {
		return results[0];
	});
}


const urlForAsset = (source) => {
  return builder.image(source).url()
}


const fetchReference = async (ref, type) => {
	if (!ref) { return null };
	return client.fetch(`*[_type == "${type}" && _id == "${ref._ref}"]`).then(response => {
		return response && response[0]
	})
}


const fetchFileReference = (ref) => {
	if (!ref) { return null };
	let asset = getFile(ref, client.config());
	return asset?.asset?.url
}


const subscribeBrochure = async (data) => {
	try {
		var params = {
			method: "post",
			url: "https://us-central1-coast-healthcare.cloudfunctions.net/subscribeBrochure",
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(data)
		}

		return axios(params).then(response => {
			if (response) { return response.data; }
			return null;
		}).catch((error) => {
			console.log("SUBSCRIBE ERROR: ", error);
			return null;
		});
	} catch(error) {
		console.log("SUBSCRIBE ERROR: ", error)
		return null
	}
}


export default {
	getAllData,
	urlForAsset,
	fetchReference,
	subscribeBrochure,
	fetchFileReference,
	getArticles,
	getSlug,
	getContent,
	CONTENT_TYPES
}