
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import 'whatwg-fetch';

import React from 'react';
import { render } from 'react-dom';
import App from './app/App.js';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-74309200-3', {siteSpeedSampleRate:100});

render(<App />, document.getElementById('root'));
