import { useEffect } from 'react';

const useScript = ({
  className, 
  type, 
  src,
  id
}) => {
  useEffect(() => {
    const node = document.getElementById(id);
    if (id && node) {
      node.innerHTML = ''
    }
    const script = document.createElement('script');    
    script.src = src;
    script.type = type;
    script.className = className;
    script.async = true;

    if (id) {
      node?.appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      if (id && node) {
        node.innerHTML = ''
      } else {
        // document.body.removeChild(script);
      }
    }
  }, [src, type, className, id]);
};

export default useScript;