
import React from 'react';
import './QuoteBar.css';


export default function QuoteSlide({ quote }) {

	return (
		<div className='quote-slide'>
      <blockquote className='quote-text max-width'>{quote}</blockquote>
		</div>
	);
}