
import React, { useEffect, useState } from 'react';
import './NewsDetail.css';
import { BlockContent, SingleImage } from '../../../common';
import moment from 'moment';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import API from '../../../modules/api';

export default function NewsDetail() {
  const params = useParams();
  const [article, setArticle] = useState({});
  const [images, setImages] = useState([]);

  async function fetchArticle() {
    let res = await API.getSlug(API.CONTENT_TYPES.NEWSARTICLE, params?.slug);
    if (res?.title) {
      setArticle(res);
    }
  }

  useEffect(() => {
    fetchArticle();
  }, [params])


  async function getImages() {
    var newImgs = [];
    for (var i = 0; i < article?.images?.length; i++) {
      const imgRef = article.images[i];
      if (imgRef?.image == null) {
        imgRef = await API.fetchReference(imgRef, 'singleImage');
      }
      if (imgRef?.image != null)
        newImgs.push(imgRef);
    }
    setImages(newImgs);
  }

  useEffect(() => {
    getImages();
  }, [article])

  return (
    <div className='news-detail content'>
      <div className='max-width'>
        {images.length > 0 && article?.showCarousel && <Carousel
          showIndicators={false}
          renderArrowPrev={(x) => <FiChevronLeft className={"news-detail-carousel-chevron"} size={30} onClick={x} />}
          renderArrowNext={(x) => <FiChevronRight className={"news-detail-carousel-chevron-right"} size={30} onClick={x} />}
          statusFormatter={() => ""}>
          {images?.map((x, i) => {
            return <div className='news-detail-carousel-slide' key={i}>
              <img src={API.urlForAsset(x.image)} alt={x.title} />
              <p>{x.title}</p>
            </div>
          })}
        </Carousel>}
        {!!article?.publishedAt && <div className="news-summary-date news-detail-date">{moment(article?.publishedAt).format('Do MMMM YYYY')}</div>}
        {!!article?.title && <h4>{article?.title}</h4>}
        {article?.body?.length > 0 ?
          <BlockContent data={article.body} />
          :
          <div className='news-detail-legacy' dangerouslySetInnerHTML={{ __html: article?.legacyHTML }} />
        }
        <div className='max-width news-detail-inline-images-container'>
        {!article?.showCarousel && images.length > 0 && <div className='news-detail-inline-images'>
          {images?.map((x, i) => {
            return <SingleImage single data={x} key={i}/>
          })}
        </div>}
        </div>
      </div>
    </div>
  );
}