
import React, { useState, useEffect} from 'react';
import API from '../../modules/api';
import './OurHomes.css';
import { BasePage, Carousel, BlockContent, QuoteBar, ContactBar } from '../../common';
import { useParams } from 'react-router-dom';
import useScript from '../../modules/hooks/useScript';
import WhereAreWe from './WhereAreWe';


export default function CareHome() {
  const params = useParams();
	const [data, setData] = useState({});


	async function getData() {
		let response = await API.getSlug(API.CONTENT_TYPES.CAREHOME, params.slug);
		setData(response)
	}

	useEffect(() => {
		getData()
	}, [])

  // useScript({
	// 	className: 'tg-review-widget',
	// 	type: 'text/javascript',
	// 	src: data?.chukScript ?? ""
	// })

  // useScript({
	// 	id: 'tg-aggregate-rating',
	// 	type: 'text/javascript',
	// 	src: "https://api.carehome.co.uk/assets/js/aggregate_rating.js?displayid=17136"
	// })
  
  useScript({
		id: 'cc-container',
		type: 'text/javascript',
		src: data?.ccScript ?? "https://google.com"
	})


	return (
		<BasePage>
			{!!data?.carousel && <Carousel _data={data?.carousel} />}
			<div className="content">
				{!!data?.body && <BlockContent data={data?.body} />}
        {!!data?.showRating && <div className='ch-rating'>
  				{/* <div className="tg-review-widget-container" id={data?.chukId}/> */}
          <div id="cc-container"/>
        </div>}
			</div>
			{!!data?.quotes && <QuoteBar quotes={data?.quotes}/>}
      <WhereAreWe data={data}/>
      <ContactBar />
		</BasePage>
	);
}