
import React, { useState } from 'react';
import './ImageBlock.css'
import SingleImage from './SingleImage';
import ImageViewer from '../ImageViewer';


export default function ImageBlock({ 
  className,
  data 
}) {
	// const [visible, setVisible] = useState(false);

	function onClick(index) {
		// setVisible(true);
	}
	
	return (
		<div className={"ib max-width-extra " + className}>
		 	{data?.images?.map((img, i) => {
		 		return <SingleImage key={i} data={img} onClick={onClick}/>
		 	})}
			 {/* <ImageViewer images={data?.images} visible={visible}/> */}
		</div>
	);
}