import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import './Header.css';
import logo from '../assets/cch-logo-light-2.png';

const Header = forwardRef(({
	navigate,
	toggleNav,
	navVisible,
	smallScreen,
	data,
}, ref) => {

	const history = useHistory({});
	const [items, setItems] = useState([]);
	const nav = useRef();

	function bannerClicked() {
		const link = data?.bannerLink;
		if (!link) return;

		if (link.lastIndexOf('http', 0) === 0)
			window.location.href = link;
		else
			history.push(link);
	}

	function onMouse(enter, i) {
		let newItems = [
			...items
		];
		newItems.forEach((item, x) => {
			let navItem = data?.headerItems[x];
			item.visible = (x === i && navItem?.subItems?.length > 0) ? enter : false;
		})

		setItems(newItems);
	}


	useEffect(() => {
		var newItems = [];
		for (var i = 0; i < data?.headerItems?.length; i++) {
			const item = data?.headerItems[i];
			const div = nav?.current?.children[i];
			newItems.push({ title: item.title, visible: false, top: div?.offsetTop ?? 0, left: div?.offsetLeft ?? 0 })
		}
		setItems(newItems)
	}, [toggleNav, navVisible, nav, data, nav?.current?.children])



	return (
		<div className='header' ref={ref}>
			<div className='header-logo-container'>
				<div className='header-logo max-width'>
					<NavLink onClick={navigate} exact to="/"><img className="header-logo-image" src={logo} alt='logo' /></NavLink>
				</div>
			</div>
			<div className='header-nav-bar'>
				<div className='header-nav'>
					<div className={navVisible ? ' header-burger-open' : 'header-burger'} onClick={toggleNav} >
						{navVisible ? <IoMdClose size={30} /> : <IoMdMenu size={30} />}
					</div>
					<CSSTransition
						in={navVisible || !smallScreen}
						timeout={350}
						classNames='nav-animation'
						unmountOnExit>
						<nav ref={nav} className='nav-items'>
							{data?.headerItems?.map((x, i) => {
								return <NavLink
									onClick={navigate}
									key={i}
									exact={x.target === '/'}
									onMouseEnter={() => onMouse(true, i)}
									onMouseLeave={() => onMouse(false, i)}
									className='header-link'
									activeClassName='header-link-active'
									to={x.target}>
									{x.title}
								</NavLink>
							})}
						</nav>
					</CSSTransition>
				</div>
				{data?.headerItems?.map((x, i) => {
					const item = items[i];
					return <div
						key={i}
						onMouseEnter={() => onMouse(true, i)}
						onMouseLeave={() => onMouse(false, i)}
						className='header-dropdown'
						style={{
							top: (item?.top ?? 0) + 17,
							left: (item?.left ?? 0) - 20,
							display: item?.visible ? 'flex' : 'none'
						}}
					>
						{x?.subItems?.map((subItem, j) => {
							return <NavLink
								key={j}
								className='header-dropdown-item'
								activeClassName='header-dropdown-item-active'
								to={x?.subItemTargets[j]}
								onClick={navigate}
							>
								{subItem}
							</NavLink>
						})}
					</div>
				})}
			</div>
			{!!data?.banner &&
				<div className='header-banner' onClick={bannerClicked}>
					<p className='header-banner-text'>{data?.bannerTitle}</p>
				</div>
			}
		</div>
	);
})

export default Header