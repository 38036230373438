import React, { useState, useEffect } from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import API from './modules/api';
import Store from './modules/store';
import ScrollToTop from './common/ScrollToTop';

import Header from './header/Header';
import Footer from './footer/Footer';
import {
	Home,
	OurCare,
	CareHome,
	News,
	NewsDetail,
	Contact,
	GenericPage
} from './pages';


export default function App() {
	const [settings, setSettings] = useState({});
	const [navVisible, setNavVisible] = useState(true);
	const [smallScreen, setSmallScreen] = useState(false);

	useEffect(() => {
		getData();
		const mediaQuery = window.matchMedia('(max-width: 840px)');
		mediaQuery.addEventListener('change', handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);
		return mediaQuery.removeEventListener('change', handleMediaQueryChange);
	}, [])


	function handleMediaQueryChange(mediaQuery) {
		setSmallScreen(mediaQuery.matches);
		if (mediaQuery.matches) {
			setNavVisible(false);
		}
	}


	async function getData() {
		const response = await API.getContent(API.CONTENT_TYPES.SETTINGS);
		setSettings(response)
	}


	function toggleNav() {
		setNavVisible(!navVisible);
	}


	function navigate() {
		if (smallScreen) {
			setNavVisible(false);
		}
	}
	

	return (
		<Provider store={Store.store}>
			<Router forceRefresh>
				<ScrollToTop />
				<Header
					toggleNav={toggleNav}
					navigate={navigate}
					smallScreen={smallScreen}
					navVisible={navVisible}
					data={settings}
				/>
					<Switch>
						<Route exact path="/" component={Home}/>
						<Route path="/our-care" component={OurCare}/>
						<Route exact path="/care-homes" component={GenericPage}/>
						<Route path="/care-homes/:slug" component={CareHome}/>
						<Route path="/pages/:slug" component={GenericPage}/>
						<Route exact path="/news" component={News}/>
						<Route path="/news/:slug" component={NewsDetail}/>
						<Route path="/contact" component={Contact}/>
					</Switch>
				<Footer data={settings} />
			</Router>
		</Provider>
	);
}