
import React, { useEffect, useState } from 'react';
import './Carousel.css'
import BSCarousel from 'react-bootstrap/Carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import CarouselSlide from './CarouselSlide';
import API from '../../modules/api';

export default function Carousel({
  className = '',
  _data
}) {
	const [data, setData] = useState({});
  
	async function fetchData() {
		let response = await API.fetchReference(_data, "carousel");
		setData(response);
	}


	useEffect(() => {
		fetchData();
	}, [_data])


	return (
		<div className={'carousel ' + className}>
			<BSCarousel
				interval={10000}
				controls={data?.items?.length > 1}
				indicators={data?.items?.length > 1}
				className='home-carousel'
				prevIcon={<FiChevronLeft className={"carousel-chevron"} size={30} />}
				nextIcon={<FiChevronRight className={"carousel-chevron"} size={30} />}>
				{
					data?.items?.map((x, i) => {
						return (
							<BSCarousel.Item key={i}>
								<CarouselSlide item={x} />
							</BSCarousel.Item>
						)
					})
				}
			</BSCarousel>
		</div>
	);
}